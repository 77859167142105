import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Excel2 from "./component/excel2";
// import Login from "./Login";
// import Home from "./pages/Home";
import Myntraforward from "./calculator/Myntraforward";
import Flipkart from "./calculator/Flipkart";
import Main from "./aspfinder/flipkart/Main";
// import aspfinder from "./pages/aspfinder";
import Mynmain from "./aspfinder/myntra/Mynmain";
import Amzmain from "./aspfinder/amazon/Amzmain";
// import Amzprimemain from "./flipkartnew/Amzprimemain";
// import Amzfbamain from "./flipkartnew/Amzfbamain";
import Amazon from "./calculator/Amazon.js";
import Loginpage from "./pages/authentication/Loginpage";
import Layout from "./pages/Layout";
import Test from "./Test";
import Home from "./pages/Home";
import AddData from "./Data_adding_page/AdminPage.js";
import Myntra_ppmp from "./Rebates/Myntra_.js";
import Myntra_Opt from "./optin/Myntra_.js"
import ContextProvider from "./Data_adding_page/ContextProvider.js";
import "./css/appp.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aspfinderflipkart" element={<Main />} />
          <Route path="aspfindermyntra" element={<Mynmain />} />
          <Route path="aspfindereasyship" element={<Amzmain />} />
          <Route path="aspfinderprimeonly" element={<Amzmain />} />
          <Route path="aspfinderfba" element={<Amzmain />} />
          {/* 
          <Route path="/aspfinder" element={<Excel2 />} />
          
          */}
          <Route path="aspcalculator-myntra" element={<Myntraforward />} />
          <Route path="aspcalculator-flipkart" element={<Flipkart />} />
          <Route path="aspcalculator-amazon" element={<Amazon />} />

          <Route path="Flipkart-add-data" element={<AddData />} />
          <Route path="Myntra-add-data" element={<AddData />} />
          <Route path="Amazon-add-data" element={<AddData />} />
          <Route path="RebateMyntraPPMP" element={<Myntra_ppmp />} />
          <Route path="OptinMyntraPPMP" element={<Myntra_Opt />} />


          {/* <Route path="Flipkart-add-data" element={<ContextProvider />} /> */}

          <Route path="test" element={<Test />} />
        </Route>
        <Route path="login" element={<Loginpage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
